<template functional>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 19H3C1.895 19 1 18.105 1 17V3C1 1.895 1.895 1 3 1H17C18.105 1 19 1.895 19 3V17C19 18.105 18.105 19 17 19Z"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 13H5.382C5.761 13 6.107 13.214 6.276 13.553L6.724 14.447C6.893 14.786 7.239 15 7.618 15H12.382C12.761 15 13.107 14.786 13.276 14.447L13.724 13.553C13.893 13.214 14.239 13 14.618 13H19"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 5H15"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5 9H15"
      stroke="#FF9933"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconMenu3",
};
</script>

<style></style>
